.header {
  font-size: 35px;
  line-height: 35px;
  font-weight: bold;
  padding-top: 24px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}

.singleLine {
  height: 124px;
}
