.header, .head, .layout  {
  background: #F3F3F3;
}

.container {
  min-height: 100%;
  position: relative;
  padding: 0;
  background: #FFF;
}

.head {
  padding-left: 30px;
}

.date {
  margin: 0;
}
.dateLabel {
  font-size: 15px!important;
}

.section1 {
  padding : 0 20px;
  padding-bottom: 40px;
  background: #F3F3F3;
}
.section2 {
  padding: 40px 20px 20px 20px;
  background: #FFF;
}

.sectionTitle {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0 20px 0;
}

.row {
  padding: 10px 0 0;
}

.row.contactRow {
  margin: 10px 0 0 0;
}

.row.panel {
  background: #FFF;
  padding: 20px 20px 0 20px;
  border-radius: 15px;
}

.row:last-child {
  margin-bottom: 20px;
}
.row .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.row .title button {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  color: #0089FF;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}
.row .text {
  font-size: 17px;
  line-height: 20px;
  margin-top: 7px;
  height: 40px;
  display: flex;
  align-items: center;
}

.input > input {
  box-sizing: border-box;
  height: 45px;
  border: none;
  border-bottom: 1px solid #D8D8D8;
  border-radius: 0;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 10px 15px 0px;
  font-weight: 500;
}

.input > input:focus {
  box-shadow: none;
  border-color: #0089ff;
}
.input > input::-webkit-input-placeholder {
  font-weight: 500;
}
.input > input:-ms-input-placeholder {
  font-weight: 500;
}
.input > input:-moz-placeholder {
  font-weight: 500;
}
.input > input::-moz-placeholder {
  font-weight: 500;
}
.input > input::placeholder {
  font-weight: 500;
}

.textarea > textarea
{
  box-sizing: border-box;
  height: 192px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  padding: 20px;
  border-radius: 15px;
}
.textarea > textarea::placeholder {
  opacity: 1;
  color: #9B9B9B;
}
.textarea > textarea:focus {
  box-shadow: none;
  border-color: #0089ff;
}

.error input {
  border-bottom: 1px solid rgb(242, 0, 0);
}
.error textarea {
  border-bottom: 1px solid rgb(242, 0, 0);
}

.errorText input {
  color: rgb(242, 0, 0);
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  padding-bottom: 10px;
}

.line {
  border-top: 1px solid #D8D8D8;
}

.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0089ff;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.fileName.done {
  text-align: left;
  padding-right: 27px;
}

.uploaded {
  color: #000;
}
.uploadConatiner {
  margin: 10px 0 0;
}

.upload {
  cursor: pointer;
  height: 73px;
  border-radius: 12px;
  border: 1px dashed #0089ff;
  border-color: #0089ff;
  margin: 10px 0px;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  justify-content: center;
  background: #FFFFFF;
  padding: 5px 20px;
}
.upload.error {
  border-color: rgb(242, 0, 0);
  color: rgb(242, 0, 0);
}
.upload.done {
  border: none;
  border-radius: 0px;
  justify-content: flex-start;
  margin: 0px;
  border-bottom: 1px solid rgb(167 167 169 / 50%);

}
.files .upload.done:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.files .upload.done:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
}

.upload svg {
  margin-left: 20px;
}

.upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  font-weight: bold;
}

.closeIcon {
  position: absolute;
  right: 20px;
}

.testImage {
  width: 0;
  height: 0;
  display: none;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin: 20px 20px 0 20px;
}
.errorBlock.hidden {
  display: none;
}

.linkBtn {
  margin: 40px 0 0px 0;
}
.linkBtn button {
  color: #0089FF;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

