.maintenance {
  height: 100vh;
  background: #fff;
}

.header {
  display: flex;
  height: 107px;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 30px;
  height: calc(100vh - 107px);
}

.content h1 {
  font-size: 35px;
  margin: 30px 0 5px 0;
}
.content p {
  margin: 0;
  font-size: 17px;
  line-height: 24px;
}
