/* used in router */
.header, .head {
  background: #F3F3F3;
}
.layout {
  background: #F3F3F3;
}
.container {
  background: #F3F3F3;
  padding-right: max(12px, env(safe-area-inset-right));
  padding-left: max(12px, env(safe-area-inset-left));
}
.head {
  padding-right: max(20px, env(safe-area-inset-right));
  padding-top: 20px;
}

.page {
  min-height: 100%;
  position: relative;
}

.title {
  margin: 0px;
}

.separator {
  margin: 40px 0 20px 0;
}
.separator.first {
  margin-top: 20px;
}
.separator .date {
  font-size: 16px;
}
.separator .number {
  font-size: 12px;
}
.separator hr.line {
  width: 100%;
  border-top: 1px solid #D8D7D6;
  margin: 5px 0;
}
