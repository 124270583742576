.content {
}

.layout {
  background: #FFF;
}
.head{
  padding-left: 30px;
  font-size: 40px;
  line-height: 40px;
}

.description {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  background: #FFF;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.singleRow {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.row {
  padding: 0 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.singleRow > .row {
  padding: 0;
  width: 45%;
}
.row .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #A7A7A9;
}

.row .title button {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  color: #0089FF;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}
.row .text {
  font-size: 17px;
  line-height: 20px;
  margin-top: 7px;
}

.input > input,
.input > select {
  box-sizing: border-box;
  height: 50px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 23px;
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}
.input > input:focus,
.input > select:focus {
  box-shadow: none;
  border-color: #0089ff;
}
.input > input::-webkit-input-placeholder {
  font-weight: 500;
}
.input > input:-ms-input-placeholder {
  font-weight: 500;
}
.input > input:-moz-placeholder {
  font-weight: 500;
}
.input > input::-moz-placeholder {
  font-weight: 500;
}
.input > input::placeholder,
.input > select::placeholder {
  font-weight: 500;
}

.select select {
  font-weight: bold;
  height: 50px;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.select select:invalid {
  color: #9b9b9b;
}

.error input {
  border-bottom: 1px solid rgb(242, 0, 0);
}

.errorText input {
  color: rgb(242, 0, 0);
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
}
.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
}

.btnBlack {
  position: relative;
  margin-top: 30px;
  margin: 40px 40px 40px;
  width: calc(100% - 80px);
  height: 48px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}

.btnRed {
  position: relative;
  margin: 0px 40px 40px 40px;
  width: calc(100% - 80px);
  height: 48px;
  border-radius: 24px;
  background-color: #FFF;
  color: #FE3A32;
  border: 1px solid #FE3A32;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}

.btnType {
  height: 35px;
  border: 0;
  border-radius: 24px;
  background-color: #F3F3F3;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding: 9px 20px;
  margin-right: 10px;
}
.btnType.typeSelected {
  background-color: #0089FF;
  color: #FFF;
}

.line {
  border-top: 1px solid #D8D8D8;
}

.contact {
  padding: 0;
  margin-top: 20px;
  color: #000;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7px;
}

.address1 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
}
.address2 {
  font-size: 17px;
  line-height: 22px;
  margin-top: 7px;
}

.support {
  color: #000;
}

.buttons {
  padding-top: 20px;
}

button.link {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.24px;
  cursor: pointer;
  margin: 40px 40px 20px;
  display: block;
}
