.btn {
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  line-height: 16px;
  cursor: pointer;
}

.btn:active {
  opacity: 0.7;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
