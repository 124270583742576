.header, .head, .layout  {
  background: #F3F3F3;
}

.container {
  min-height: 100%;
  position: relative;
  padding: 0 20px;
  padding-bottom: 20px;
}

.head {
  padding-left: 10px;
}

.date {
  margin: 0;
}
.dateLabel {
  font-size: 15px!important;
}

.row {
  margin: 10px 0;
}

.row.contactRow {
  margin: 5px 0 0 0;
}

.row.panel {
  background: #FFF;
  padding: 20px 20px 0 20px;
  border-radius: 15px;
}

.row:last-child {
  margin-bottom: 20px;
}
.row .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.row .title button {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  color: #0089FF;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}
.row .text {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  margin-top: 7px;
  height: 40px;
  display: flex;
  align-items: center;
}

.textarea {
  box-sizing: border-box;
  height: 192px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 17px;
  line-height: 20px;
  padding: 20px;
  border-radius: 15px;
  margin-top: 10px;
  word-break: break-all;
  overflow-y: auto;
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
}

.sectionTitle {
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0 20px 0;
}

.btnBlack {
  margin: 20px 20px 20px;
  width: calc(100% - 40px);
  height: 48px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}

.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0089ff;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: -0.5px;
}
.fileName.done {
  text-align: left;
  padding-right: 27px;
}

.uploaded {
  color: #000;
}
.uploadConatiner {
  margin: 10px 0;
}

.upload {
  cursor: pointer;
  height: 73px;
  border-radius: 12px;
  border: 1px dashed #0089ff;
  border-color: #0089ff;
  margin: 10px 0px;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  justify-content: center;
  background: #FFFFFF;
  padding: 5px 20px;
}
.upload.error {
  border-color: rgb(242, 0, 0);
  color: rgb(242, 0, 0);
}
.upload.done {
  border: none;
  border-radius: 0px;
  justify-content: flex-start;
  margin: 0px;
  border-bottom: 1px solid rgb(167 167 169 / 50%);

}
.files .upload.done:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.files .upload.done:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
}

.upload svg {
  margin-left: 20px;
}

.upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  font-weight: bold;
}

.closeIcon {
  position: absolute;
  right: 20px;
}

.testImage {
  width: 0;
  height: 0;
  display: none;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin: 20px 20px 0 20px;
}
.errorBlock.hidden {
  display: none;
}

.linkBtn button {
  color: #0089FF;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

hr.line {
  border-top: 1px solid #D8D7D6;
  margin: 40px auto 40px;
}
