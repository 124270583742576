/* used in router */
.header {
  background: #000;
}

.layout {
  background: #000;
}

.title {
  color: #fff;
  margin-top: 50px;
}

.description {
  color: #fff;
  margin: 30px 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}

.date {
  font-weight: bold;
  font-size: 21px;
}

.actions {
  position: sticky;
  top: calc(100% - 115px);
}

.btn {
  margin: 40px 20px 40px;
  width: calc(100% - 60px);
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}
