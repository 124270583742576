.layout {
  background: #FFF;
}

.content {
}
.container {
  background: #FFF;
}

.header {
  padding-left: 20px;
}

.description {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 20px;
  padding-left: 25px;
  background: #FFF;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.row {
  padding: 20px 20px;
}
.row:last-child {
  margin-bottom: 20px;
}
.row .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.row .title button {
  border: none;
  outline: none;
  background: none;
  font-size: 15px;
  color: #0089FF;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}
.row .text {
  font-size: 17px;
  line-height: 20px;
  margin-top: 7px;
}

.input > input {
  box-sizing: border-box;
  height: 60px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 17px;
  line-height: 20px;
  padding: 20px 20px 20px 0px;
  font-weight: 500;
}
.input > input:focus {
  box-shadow: none;
  border-color: #0089ff;
}
.input > input::-webkit-input-placeholder {
  font-weight: 500;
}
.input > input:-ms-input-placeholder {
  font-weight: 500;
}
.input > input:-moz-placeholder {
  font-weight: 500;
}
.input > input::-moz-placeholder {
  font-weight: 500;
}
.input > input::placeholder {
  font-weight: 500;
}

.inputDescription {
  margin: 20px 0 0 0;
}

.error input {
  border-bottom: 1px solid rgb(242, 0, 0);
}

.errorText input {
  color: rgb(242, 0, 0);
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
}
.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
}

.line {
  border-top: 1px solid #D8D8D8;
}

.contact {
  padding: 0;
  margin-top: 20px;
  color: #000;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7px;
}

.address1 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
}
.address2 {
  font-size: 17px;
  line-height: 22px;
  margin-top: 7px;
}

.support {
  color: #000;
}
