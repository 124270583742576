/* used in router */
.header, .layout  {
  background: #A7A7A9;
}

.title {
  color: #fff;
  padding-top: 80px;
}

.description {
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  margin: unset;
  margin-bottom: 30px;
  margin-right: 20px;
}

.chatButton {
}

.btn {
  width: 100%;
  margin: 40px 0px 40px;
  height: 48px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  line-height: 21px;
}
