.task {
  background-color: #FFF;
  position: relative;
  align-items: center;
  margin: 10px 0;
  text-decoration: none;
  color: #000;
  border-radius: 15px;

  -webkit-box-shadow: 0px 9px 13px -2px rgba(222,222,222,0.18);
  -moz-box-shadow: 0px 9px 13px -2px rgba(222,222,222,0.18);
  box-shadow: 0px 9px 13px -2px rgba(222,222,222,0.18);
}

.task.pointer {
  cursor: pointer;
}

.task .label {
  position: relative;
  width: 100%;
  padding: 25px 35px 25px 25px;
}
.disabled {
  opacity: 0.5;
}
.task .label h3 {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: -0.18px;
}
.task .label p {
  margin: 10px 0 25px;
  font-size: 16px;
  line-height: 20px;
}
.task .label p.note {
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  color: #838383;
}
.task .label .action {
  display: flex;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: #000;
}

.icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.icon span {
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
}

.iconCenter {
  position: absolute;
  right: 20px;
  top: calc(50% - 5px);
}

.line {
  border-top: 1px solid #D8D8D8;
  margin: 15px 0;
}

.green {
  color: #24DB96;
}
.red {
  color: rgb(242, 0, 0);
}

.btn {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #0089ff;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}

.progressBar {
  border-radius: 5px;
  height: 40px;
  margin-right: -42px;
  overflow: hidden;
  width: calc(100% + 42px) !important;
}

.task .label p.progress {
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  margin: 5px -42px 0 0;
}
.progress .amount {
  font-weight: bold;
}
.progress .name {
  color: #0089ff;
}
.progress .name::after {
  content: ",";
  color: #000;
}
.progress .name:last-child::after {
  display: none;
}

.details {
  padding: 0 20px 20px;
}
.details .summary {
  margin: 20px 0;
  color: #A7A7A9;
  font-size: 13px;
}
.details .payments {

}
.details .payments .info {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.7);
  }
  100% {
    transform: scale(1);
  }
}
