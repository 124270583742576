/* used in router */
.header, .head  {
  background: #F3F3F3;
}

.layout {
  background: #FFF;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main {
  flex-grow: 1;
}

.form {
  height: 100%;
}

.subTitle {
  font-size: 16px;
  line-height: 21px;
  padding: 20px 0;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 20px;
  text-align: center;
  min-height: 18px;
}

.checkbox {
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 54px;
  border-radius: 5px;
  color: #0089ff;
}

.checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkmark {
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #000;
}

.paymentMethod {
  position: relative;
  height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.cardNumber {
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #000;
  font-weight: 500;
  line-height: 30px;
}

.icons {
  position: absolute;
  right: 10px;
  display: flex;
}

.cardIcon {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.cardIconSelected {
  width: 30px;
  height: 30px;
}

.card {
  margin: 0px 10px;
}
