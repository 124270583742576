.setting {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  text-decoration: none;
  color: #000;
  padding: 15px 35px 15px 25px;
  height: 72px;
  border-radius: 12px;
  background-color: #FFF;
}
.setting .settingIcon {
  position: absolute;
  left: 30px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting .settingText h4 {
  margin: 0 0 3px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}
.setting .settingRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting .arrowIcon {
  position: absolute;
  right: 12px;
  width: 21px;
  height: 21px;
}

.setting .arrowIcon {
  position: absolute;
  right: 20px;
  width: 21px;
  height: 21px;
}

.setting .circleIcon {
  margin-right: 10px;
}

