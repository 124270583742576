/* used in router */
.header, .head  {
  background: #F3F3F3;
}

.head {
  border-bottom: 1px solid #D8D7D6;
}

.layout {
  background: #FFF;
}

.container {
  background: #FFF;
}
.container.hidden {
  opacity: 0;
}

.subTitle {
  font-size: 16px;
  line-height: 21px;
  padding: 20px 0;
}

.subHeader {
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 13px;
  line-height: 18px;
}

.summaryContainer {
  background: #F3F3F3;
  padding: 20px;
}

.summary {
  background: #FFF;
  border-radius: 15px;
}
.summaryFooter {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summaryDate {
  color: #A7A7A9;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.summaryToggle {
  cursor: pointer;
  color: #000;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.summaryDetails {
  padding: 20px;
}

.summaryDetailsRow {
  display: flex;
  justify-content: space-between;
  margin: 7px 0;
}

.summaryDetailsRowFirst {
  display: flex;
  justify-content: space-between;
  margin: 7px 0;
  margin-bottom: 15px;
}
.summaryDetailsRowFirst div {
  font-weight: bold;
}

.summaryDetailsDescription {
  margin-top: 20px;
}

.pricing {
  border-bottom: 1px solid #D8D7D6;
  padding: 20px 0px 20px 40px;
  margin: 0;
}

.paymentMethods {
  background: #FFF;
  padding: 20px 40px;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
  min-height: 18px;
}

.checkbox {
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 54px;
  border-radius: 5px;
  color: #0089ff;
}

.checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkmark {
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #000;
}

.paymentMethod {
  position: relative;
  height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.cardNumber {
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #000;
  font-weight: 500;
  line-height: 30px;
}

.icons {
  position: absolute;
  right: 10px;
  display: flex;
}

.cardIcon {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.cardIconSelected {
  width: 30px;
  height: 30px;
}

.card {
  margin: 0px 10px;
}

.addNew {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid #f1f1f1;
  cursor: pointer;
  margin-top: 15px;
  padding-top: 16px;
  padding-left: 1px;
}

.addNew.noMethods {
  margin-top: 10px;
  padding-top: 0px;
  position: relative;
  border: none;
}
.noMethods > svg {
  display: inline-block;
}

.new {
  color: #0089ff;
  font-size: 15px;
  line-height: 27px;
}
.plusIcon {
  margin-right: 15px;
}
.noMethods .new {
  color: #0083ff;
  font-size: 17px;
  line-height: 27px;
}

.title {
  margin: 82px 36px 0px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
}

.form {
  height: 100%;
  background: #FFF;
}
.form.hidden {
  opacity: 0;
}

.formGroup {
  display: flex;
  align-items: center;
  padding: 25px 0px 0 0;
}

.formGroup label {
  font-size: 15px;
  line-height: 17px;
  margin-left: 8px;
  margin-bottom: 9.5px;
}

.formGroup.noMarginBottom {
  margin-bottom: 0;
}

.inputContainer {
  width: 100%;
}

.input {
  position: relative;
}

.input > input {
  box-sizing: border-box;
  height: 60px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 17px;
  line-height: 20px;
  padding: 20px 20px 20px 20px;
  font-weight: 500;
}
.input > input:focus {
  box-shadow: none;
  border-color: #0089ff;
}
.input > input::-webkit-input-placeholder {
  font-weight: 500;
}
.input > input:-ms-input-placeholder {
  font-weight: 500;
}
.input > input:-moz-placeholder {
  font-weight: 500;
}
.input > input::-moz-placeholder {
  font-weight: 500;
}
.input > input::placeholder {
  font-weight: 500;
}
.expiryInput {
  width: 35%;
}

.cvvInput {
  width: 25%;
}

.input.zipInput {
  width: 40%;
}

.error input {
  border-bottom: 1px solid rgb(242, 0, 0);
}

.errorText input {
  color: rgb(242, 0, 0);
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
}
.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 25px;
  text-align: center;
}

.buttons {
  position: sticky;
  width: 100%;
  top: calc(100% - 129px);
  display: flex;
  align-items: center;
}

.submitButton {
  height: 49px;
  width: 172.31px;
  border-radius: 30px;
  background-color: #0089ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 40px 0px;
}

.powered {
  display: flex;
  font-size: 12px;
  line-height: 24px;
  padding: 0px 0px 10px 0;
}
.powered > img {
  height: 20px;
}
.disclosure {
  margin: 30px 0px 0px 0px;
}
.disclosure a {
  color: #000;
}

.checkboxContainer .checkbox {
  color: #fff;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  height: 32px;
  margin: 40px 0 0px 0;;
}

.checkboxLabel {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}
.checkboxLabel a {
  text-decoration: none;
  color: #0089FF;
}

.checkboxContainer .checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkboxContainer .checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer .checkmark {
  left: 0px;
  top: 15px;
  border: 2px solid #0089FF;
}

.link {
  color: #0083FF;
  font-size: 14px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
