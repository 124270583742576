/* used in router */
.header, .layout  {
  background: #F3F3F3;
}

.head {
  background: #F3F3F3;
}
.container {
  background: #F3F3F3;
}

.uploadContainer {
  margin-top: 35px;
}

.form {
  height: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 20px;
}
.errorBlock.hidden {
  display: none;
}

.select select {
  font-weight: bold;
  margin-left: -3px;
  border: 0;
}
.select select:invalid {
  color: #9b9b9b;
}


.inputContainer {
  margin-top: 35px;
  font-size: 17px;
  letter-spacing: 0;
}

.input {
  height: 60px;
  border: 0;
  transition: all 0.5s;
}
.input.error {
  border-color: rgb(242, 0, 0);
}

.input input {
  height: 100%;
  border: none;
  font-size: 17px;
  transition: all 0.5s;
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 0;
  font-weight: bold;
}

.input input::placeholder {
  color: #9b9b9b;
  opacity: 1;
}
.input.error input {
  border-bottom: 1px solid rgb(242, 0, 0);
}

.dateInput {
  position: relative;
  border: 0;
}

.dateInput input {
  opacity: 0;
}

.dateInput svg {
  position: absolute;
  right: 8%;
  top: 20px;
}

.dateInput .dateInputIcon {
  position: absolute;
  right: 0px;
  top: 22px;
  color: #0083FF;
  font-size: 17px;
  font-weight: 500;
}

.value {
  position: absolute;
  top: 35%;
  font-weight: bold;
}
.value.error {
  color: rgb(242, 0, 0);
}

.checkboxContainer .checkbox {
  color: #fff;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  height: 32px;
  margin: 14px 0 14px 0;
}

.checkboxLabel {
  font-size: 17px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.checkboxContainer .checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkboxContainer .checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer .checkmark {
  left: 0px;
  top: 5px;
  border: 2px solid #0089FF;
}

hr.line {
  border-top: 1px solid #D8D8D8;
}
