.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0089ff;
  text-align: center;
  font-weight: bold;
}
.fileName.done {
  text-align: left;
}

.fileName.uploaded {
  color: #000;
  margin-right: 30px;
}

.upload {
  cursor: pointer;
  height: 73px;
  border-radius: 12px;
  border: 1px dashed #0089ff;
  border-color: #0089ff;
  margin: 10px 0px;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  justify-content: center;
  background: #FFFFFF;
  padding: 5px 20px;
}
.upload.error {
  border-color: rgb(242, 0, 0);
  color: rgb(242, 0, 0);
}
.upload.done {
  border: none;
  border-radius: 0px;
  justify-content: flex-start;
  margin: 0px;
  border-bottom: 1px solid rgb(167 167 169 / 50%);

}
.files .upload.done:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.files .upload.done:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
}

.upload svg {
  margin-left: 20px;
}

.upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  font-weight: bold;
}

.closeIcon {
  position: absolute;
  right: 20px;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 20px;
}
.errorBlock.hidden {
  display: none;
}
