.buttonContainer {
  padding: 20px 0;
}
.button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #0083ff;
  color: #0083ff;
  padding: 13px 20px;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
}

.button > span {
  padding-left: 7px;
}

.helpContainer {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 10px 30px 10px 20px;
}
.text {
  font-size: 14px;
  margin: 0px;
  text-align: center;
}

.help {
  cursor: pointer;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: inherit;
  text-decoration: none;
}
.help.email {
  height: auto;
  width: auto;
  margin-top: 0;
}

.help img {
  width: 25px;
  height: 24px;
}

.contact {
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: #fff;
}
.contact svg {
  margin-right: 15px;
  height: 35px;
  width: 35px;
  min-width: 35px;
}
.contact div {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.2px;
}
.contact a {
  color: inherit;
}
