.dob {
  display: flex;
  margin: 10px 0 20px 0px;
}

.dob input {
  background: none;
  border: 0;
  border-bottom: 1px solid #F3F3F3;
  color: #A7A7A9;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 6px;
  padding: 0px;
  padding-left: 6px;
  margin-right: 10px;
  text-align: center;
  border-radius: 0;
}

.day {
  width: 52px;
}
.month {
  width: 57px;
}
.year {
  width: 92px;
}

.dob input::placeholder {
  opacity: 1;
  text-align: center;
}
