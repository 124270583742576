:root {
  --black: #000000;
  --dark-grey: #9b9b9b;
  --grey: #e1e1e1;
  --light-grey: #fafafa;
  --white: #ffffff;
  --red: #ff4a7a;
  --green: #0acc91;

  --max-container-width: 480px;
}
