/* used in router */
.header, .layout, .head  {
  background: #FFF;
}

.head {
  margin-top: 50px;
}

.head {
  border-bottom: 1px solid #D8D7D6;
}

.container {
  background: #FFF;
  padding-top: 30px;
}

.hr {
  border-top: 1px solid #D8D7D6;
  margin: 0px;
  margin-bottom: 30px;
}

.group {
  padding: 0 20px 30px 20px;
}

.group .title {
  font-size: 16px;
  line-height: 18px;
  color: #A7A7A9;
  margin-bottom: 10px;
}

.group .content {
  font-size: 20px;
  line-height: 25px;
}

.group .content > a {
  color: #000;
}

.group .link {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.21px;
  cursor: pointer;
  margin-top: 25px;
  display: block;
}

.linkText {
  font-size: 13px;
  line-height: 18px;
  font-style: italic;
  letter-spacing: -0.21px;
  margin-top: 25px;
}

.status {
  font-weight: bold;
  margin-right: 10px;
}

.cancelBtn {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.21px;
  cursor: pointer;
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.btnBlack {
  margin-top: 15px;
  height: 39px;
  width: 109px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

.claims {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: #000;
}

.claims .arrowIcon {
  position: absolute;
  top: 2px;
  right: 12px;
  width: 21px;
  height: 21px;
}
.claims.clickable {
  cursor: pointer;
}

.summaryDetailsRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.summaryDetailsRowFirst {
  display: flex;
  justify-content: space-between;
  margin: 7px 0;
  margin-bottom: 15px;
}
.summaryDetailsRowFirst div {
  font-weight: bold;
}

.summaryDetailsDescription {
  margin-top: 20px;
}

.summaryToggle {
  cursor: pointer;
  margin-top: 25px;
  color: #000;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.message {
  background: #ffffc4;
  border: 1px solid #ececec;
  padding: 15px;
  font-size: 13px;
}
