/* used in router */
.header, .head {
  background: #F3F3F3;
}
.layout {
  background: #F3F3F3;
}
.container {
  background: #F3F3F3;
  margin-top: 20px;
  padding-right: max(12px, env(safe-area-inset-right));
  padding-left: max(12px, env(safe-area-inset-left));
}
.head {
  padding-right: max(20px, env(safe-area-inset-right));
  padding-top: 20px;
}

.subHeader {
  font-size: 16px;
  line-height: 21px;
}

.page {
  min-height: 100%;
  position: relative;
  padding-bottom: 40px;
}

.separator {
  margin: 40px 0 20px 0;
}
.separator .date {
  font-size:  16px;
}
.separator .number {
  font-size: 12px;
}
.separator hr.line {
  width: 100%;
  border-top: 1px solid #D8D7D6;
  margin: 5px 0;
}

.setting {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  text-decoration: none;
  color: #000;
  padding: 23px;
  height: 96px;
  border-radius: 12px;
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.setting .settingIcon {
  position: absolute;
  left: 30px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting .title {
  margin: 0 0 7px 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.setting .description {
  font-size: 15px;
  line-height: 17px;
  color: #A7A7A9;

}
.setting .settingRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting .status {
  position: absolute;
  right: 17px;
  bottom: 17px;
}

.setting .arrowIcon {
  position: absolute;
  right: 12px;
  width: 21px;
  height: 21px;
  top: 40px;
}

button.link {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.24px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}
