.back {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
}
.close {
  position: absolute;
  top: 7px;
  right: 15px;
}

.white {
  color: #fff;
}
