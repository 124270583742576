.setting {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  text-decoration: none;
  color: #000;
  padding: 17px;
  height: 96px;
  border-radius: 12px;
  background-color: #FFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.setting .settingIcon {
  position: absolute;
  left: 30px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting .title {
  margin: 0 0 7px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.setting .period {
  font-size: 15px;
  line-height: 17px;
  color: #A7A7A9;

}
.setting .settingRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting .status {
  position: absolute;
  right: 17px;
  bottom: 17px;
}

.status.blue {
  color: blue;
}
.status.red {
  color: red;
}
.status.green {
  color: green;
}
