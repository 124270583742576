.iconFill {
  opacity: 0.7;
}
.iconFill g g {
  fill: black;
}

.iconFillError path {
  fill: rgb(242, 0, 0);
}
.iconFillError g g {
  fill: rgb(242, 0, 0);
}
