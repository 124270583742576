.pricing {
  position: relative;
  padding: 20px 0 10px 20px;
  display: flex;
}
.pricing > svg {
  position: absolute;
  top: -20px;
}

.price {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.price > .dollar {
  font-size: 16px;
  position: absolute;
  font-weight: 500;
  left: -10px;
  top: 15px;
}
.price > .amount {
  font-size: 60px;
  line-height: 74px;
  font-weight: bold;
  margin-right: 10px;
}
.price > .text {
  font-size: 16px;
  line-height: 40px;
}
