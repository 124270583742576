.errorPage {
  max-width: var(--max-container-width);
  margin: auto;
  color: var(--black);
  background: var(--white);
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
    "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 35px;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 10px;
}

.description {
  font-size: 17px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  margin-right: 10px;
  margin-left: 10px;
}
.description a {
  color: #000;
}
