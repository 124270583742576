.setting,
.settingRange {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px 0px 10px;
  text-decoration: none;
  color: #000;
  padding-bottom: 15px;
  border-radius: 12px;
  background-color: #ffffff;
}
.setting.disabled {
  opacity: 0.6;
}
.setting .settingTitle h4,
.settingRange .settingTitle h4 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}
.setting .settingText,
.settingRange .settingText {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  text-align: center;
  padding: 0px 20px;
}
.setting .settingControls,
.settingRange .settingControls
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.noTitle {
  padding-top: 20px;
}

.setting .settingControls .settingAmount {
  font-size: 22px;
  font-weight: bold;
  width: 150px;
  text-align: center;
}
.settingRange .settingControls .settingAmount {
  font-size: 20px;
  font-weight: bold;
  width: 120px;
  text-align: center;
}

.amountInput {
  margin: 0 10px;
  width: auto;
}
.amountInput span {
  left: 10px;
  top: 10px;
}
.amountInput input {
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  width: auto;
  text-align: center;
  padding: 5px 20px 5px 30px;
  border-bottom: 2px solid #000;
  padding-left: 40px!important;
}


.setting .line {
  margin: 20px 0 15px 0;
}

.icon {
  transform: scale(1.3);
}
