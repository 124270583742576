.modal {
  height: 47vh;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.title {
  margin: 25px 45px 0px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
}

.description {
  margin: 25px 36px 0px;
  font-size: 13px;
  line-height: 18px;
}

.actions {
  display: flex;
  margin: 40px 40px 40px 40px
}

.button {
  color: #0086ff;
  height: 49px;
  width: 172.31px;
  border-radius: 30px;
  font-size: 17px;
  font-weight: initial;
  line-height: 20px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.button.no {
  color: #ef2a2a;
}

.button.yes {
  border-radius: 24px;
  background-color: #000;
  color: #fff;
}

.button.single {
  width: 100%;
  margin: 0 20px;
}
