.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer select {
  color: #000;
  height: 60px;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 5px 30px 5px 0px;
  background: none;
  outline: none;
  transition: all 0.3s;
}
.inputContainer.hasIcon select {
  padding-left: 60px;
}
.inputContainer select:focus {
  /*border-color: #000;*/
}

.icon {
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
