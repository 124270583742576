/* used in router */
.header, .head {
  background: #F3F3F3;
}
.layout {
  background: #F3F3F3;
}
.container {
  background: #F3F3F3;
  padding-bottom: 160px;
  margin-top: 20px;
  padding-right: max(12px, env(safe-area-inset-right));
  padding-left: max(12px, env(safe-area-inset-left));
}
.head {
  padding-right: max(20px, env(safe-area-inset-right));
}

.page {
  min-height: 100%;
  position: relative;
}

.title {
  margin: 0px;
}

.setting {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  text-decoration: none;
  color: #000;
  padding: 17px 35px 17px 18px;
  height: 96px;
  border-radius: 12px;
  background-color: #FFF;
}
.setting .settingIcon {
  position: absolute;
  left: 30px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting .settingText h4 {
  margin: 0 0 7px 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
}
.setting .settingText div {
  font-size: 15px;
  line-height: 17px;
  color: #A7A7A9;

}
.setting .settingRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting .arrowIcon {
  position: absolute;
  right: 12px;
  width: 21px;
  height: 21px;
}

hr.line {
  width: 100%;
  border-top: 1px solid #D8D7D6;
  margin: 0px;
}

.support {
  position: absolute;
  width: 100%;
  bottom: 0;

  margin-top: 20px;
  padding: 30px 0;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.support h4 {
  font-size: 19px;
  font-weight: bold;
  letter-spacing: -0.47px;
  margin-top: 0;
  margin-bottom: 10px;
}
.support span {
  font-size: 15px;
  margin-bottom: 10px;
}
.support a {
  color: #000;
}

button.link {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.24px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}
