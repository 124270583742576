.header {
  background: #F3F3F3;
}

.layout {
  background: #F3F3F3;
}

.home {
  padding: 0 10px 30px;
}

.home h1 {
  margin: 0;
  padding: 0;
  font-size: 35px;
  font-weight: bold;
  line-height: 43px;
  word-break: break-word;
  overflow: hidden;
}

.home .moveInInfo {
  padding: 0 23px;
}

.home .moveInDate {
  margin: 20px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 23px;
}
.home .moveInDate .date {
  font-weight: bold;
}

.progress {
  margin: 5px 0;
  font-size: 12px;
  letter-spacing: -0.2px;
  font-weight: 500;
  line-height: 16px;
  position: absolute;
  top: 3px;
  right: 25px;
}

.progressBar {
  margin: 0;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: #d8d8d8;
  position: relative;
}
.progressBar .fill {
  transform-origin: left;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  background-color: #24DB96;
  transition: transform 0.5s;
}

.list {
  margin-top: 30px;
}

.head {
  background-color: #F3F3F3;
  padding: 50px 30px 30px;
}

.head h1 {
  color: #000;
  font-size: 40px;
  letter-spacing: -0.5px;
  margin: 0;
  margin-bottom: 20px;
  word-break: break-word;
}

.head span {
  padding: 0 3px;
}

.line {
  border-top: 1px solid #D8D8D8;
  margin: 0 0 40px 0;
}

.lineSmall {
  border-top: 1px solid #D8D8D8;
  margin: 15px 0;
}

.msg {
  margin: 20px 80px;
  text-align: center;
}
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
}
.text {
  font-size: 16px;
}

.task {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 30px 0;
  text-decoration: none;
  color: #000;
  border-radius: 8px;
}
.task h3 {
  margin: 0;
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 29px;
}
.task span {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.task .btn {
  margin-top: 20px;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  line-height: 21px;
}

.badges {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: -30px;
}
.badges svg {
  width: 150px;
  margin: 0px 10px;
  cursor: pointer;
}
