/* used in router */
.header, .head  {
  background: #F3F3F3;
}
.layout {
  max-width: 100%!important;
}

.head {
  min-height: 120px;
  display: flex;
  align-items: center;
}

.container {
  background: #FFF;
}

.content > div {
  margin-bottom: 10px;
}
.content ul {
  padding-left: 20px;
}

.question {
  border-bottom: 1px solid #D8D7D6;
  position: relative;
  padding: 25px;
}
.row {
  cursor: pointer;
  position: relative;
}
.row > svg {
  position: absolute;
  right: 0px;
  width: 30px;
  top: 50%;
}
.question .title {
  font-size: 15px;
  line-height: 20px;
  padding-right: 40px;
}
.question .text {
  padding-top: 30px;
  padding-bottom: 20px;
  padding-right: 30px;
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}
.question .text div {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 10px;
}
.question .text .title {
  font-weight: bold;
}


.container {
  overflow: scroll;
  height: 100vh;
  box-sizing: border-box;
  max-width: 100%!important;
}

.tableHeader {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 10px;
}

.content {
  overflow: scroll;
  box-sizing: border-box;
  margin: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: #eee;
}
th {
  font-weight: bold;
}
td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}
