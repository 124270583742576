.service {
  z-index: 1;
  position: relative;
  text-decoration: none;
  color: #000;
}

.service .info {
  padding-bottom: 20px;
}
.service .serviceText {
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 10px;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dateContainer svg {
  margin-right: 10px;
}
.dateContainer .date {
  font-size: 17px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: -0.2px;
}
.pointer {
  cursor: pointer;
}

.datePicker {
  width: 100%;
}

.hidden {
  display: none;
}
