/* used in router */
.header, .layout, .container  {
  background: #000;
}


.title {
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  margin: unset;
  padding: 20px 0px;
  font-weight: 500;
}

.dob {
  color: #A7A7A9;
  font-size: 14px;
  margin: 0px;
  padding-top: 80px;
}

.description {
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  margin: unset;
  padding: 20px 20px;
  position: fixed;
  bottom: 100px;
  width: 100%;
}

.btnBlack {
  position: fixed;
  bottom: 0;
  margin: 0px 20px 40px;
  width: calc(100% - 80px);
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}

@media screen and (max-height: 600px) {
  .btnBlack {
    position: relative;
    margin-top: 30px;
    width: calc(100% - 40px);
  }
}

.inputContainer .checkbox {
  color: #fff;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  height: 32px;
  margin: 60px 0 0px 0;;
}

.label {
  font-size: 14px;
}

.label a {
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}

.inputContainer .checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.inputContainer .checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.inputContainer .checkmark {
  left: 0px;
  top: 5px;
  border: 2px solid #0089FF;
}

.error {
  margin-top: 10px;
  color: red;
}
