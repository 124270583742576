@import-normalize;

* {
  font-family: "Helvetica Neue", "Arial", sans-serif;
}
html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-size: 13px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

h1 {
  color: #000;
  margin: 0;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -1px;
}


body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
}

h2 {
  font-size: 23px;
  font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
