/* used in router */
.header, .layout, .head  {
  background: #F3F3F3;
}

.container {
  background: #F3F3F3;
}

.hr {
  border-top: 1px solid #D8D7D6;
  margin: 0px;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
  min-height: 18px;
}
