/* used in router */
.header, .layout, .head  {
  background: #F3F3F3;
}

.container {
  font-size: 13px;
  line-height: 18px;
  position: relative;
  z-index: 2;
  margin: 20px;
  background: #FFF;
  border-radius: 15px;
  margin-top: 30vh;
  padding: 20px;
}

.container.uploadOnly {
  margin-top: 35vh;
}

.container h1 {
  letter-spacing: -0.2px;
  margin-bottom: 20px;
}

.image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.image img {
  width: 100%;
}

.closeBtn {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 30px;
}

.description {
  font-size: 13px;
  line-height: 18px;
  color: #000;
  margin: unset;
  padding: 8px 0px;
}

.uploadBtn {
  padding-top: 8px;
  padding-bottom: 8px;
}
.learnMore {
  padding-top: 8px;
  padding-bottom: 25px;
}
.learnMore button,
.uploadBtn button {
  color: #0083FF;
  font-size: 15px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.fee {
  font-size: 14px;
}

@media screen and (max-width: 375px) {
  .container {
    margin-top: 25vh;
  }
  .container.uploadOnly {
    margin-top: 30vh;
  }
}
