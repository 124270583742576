.title {
  margin: 20px 0px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
}

.description {
  font-size: 15px;
  margin: 20px 0px;
}

.error {
  font-size: 15px;
  color: rgb(242, 0, 0);
  margin: 20px 0px;
}

.container {
  padding: 20px;
}

.buttons {
  display: flex;
  justify-content: left;
  align-content: center;
  position: sticky;
  width: 100%;
  top: calc(100% - 129px);
}

.submitButton {
  height: 49px;
  width: 172.31px;
  border-radius: 30px;
  background-color: #0089ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
}
