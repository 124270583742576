.month span {
  color: #0a1f44;
  line-height: 23px;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.21px;
  font-weight: bold;
}

.calendarPrice {
  color: white;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  bottom: -8px;
  z-index: 20;
  left: 0px;
  width: inherit;
  background-color: #5290e0;
  border-radius: 20px;
}

.arrowLeft {
  border: 2px solid #8a94a6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 0px;
}

.arrowRight {
  border: 2px solid #8a94a6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 0px;
}

.arrowButtonLeft {
  position: absolute;
  left: -10px;
  top: 0px;
  width: 30%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowButtonRight {
  position: absolute;
  right: -10px;
  top: 0px;
  width: 30%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  color: #000;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 0 5px;
  background: none;
  outline: none;
  transition: all 0.3s;
  margin: 0 5px;
}
.selectYear {

}
.selectMonth {

}
