/* used in router */
.header, .layout, .head  {
  background: #F3F3F3;
}

.head {
  padding-right: 40px;
  min-height: 120px;
  display: flex;
  align-items: center;
}

.description {
  font-size: 13px;
  line-height: 18px;
  color: #000;
  margin: unset;
  padding: 8px 0px;
}

.description > ul {
  padding-left: 13px;
  margin-bottom: 0;
}

.descriptionContainer {
  padding-top: 20px;
}

.fee {
  font-size: 14px;
}

.content > div {
  margin-bottom: 10px;
}
.content ul {
  padding-left: 20px;
}

.question {
  background: #FFF;
  border-bottom: 1px solid #D8D7D6;
  position: relative;
  padding: 25px;
}
.row {
  cursor: pointer;
  position: relative;
}
.row > svg {
  position: absolute;
  right: 0px;
  width: 30px;
  top: 50%;
}
.question .title {
  font-size: 15px;
  line-height: 20px;
  padding-right: 40px;
}
.question .text {
  padding-top: 40px;
  padding-bottom: 20px;
  padding-right: 40px;
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}
