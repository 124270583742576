/* used in router */
.header, .head  {
  background: #F3F3F3;
}

.form {
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s;
}
.form.hidden {
  opacity: 0;
}

.formGroup {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.formGroup label {
  font-size: 15px;
  line-height: 17px;
  margin-left: 8px;
  margin-bottom: 9.5px;
}

.formGroup.noMarginBottom {
  margin-bottom: 0;
}

.expiryInput {
  width: 70%;
}

.cvvInput {
  width: 30%;
}

.errorMessage {
  color: rgb(242, 0, 0);
  margin-top: 5px;
}
.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
  min-height: 18px;
}

.btnBlack {
  position: fixed;
  bottom: 0;
  margin: 0px 20px 40px;
  width: calc(100% - 80px);
  height: 48px;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}
