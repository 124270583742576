.actions {
  position: sticky;
  top: calc(100% - 115px);
  width: 100%;
}
.actions.relative {
  position: relative;
  top: unset;
}

.btn {
  margin: 25px 40px 40px 40px;
  background-color: #000;
  color: #ffffff;
  width: calc(100% - 80px);
  height: 48px;
  border-radius: 24px;
  font-size: 17px;
  font-weight: bold;
  line-height: 22px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
