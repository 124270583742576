/* used in router */
.header, .layout  {
  background: #F3F3F3;
}

.container {
  padding-bottom: 50px;
}

.title {
  color: #000;
  margin-top: 50px;
}

.description {
  color: #000;
  margin: 10px 0px 30px 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.messageContainer {
  background-color: #000;
  padding: 40px 30px;
  border-radius: 15px;
  margin-top: 40px;
}

.subTitle {
  color: #FFF;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.22px;
  margin-bottom: 30px;
}

.text > div {
  color: #FFF;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.text a {
  color: #FFF;
  text-decoration: none;
}

.line1 {
  font-weight: 500;
  margin: 20px 0 0 0;
}
.line2 {
  font-weight: 500;
  margin: 20px 0 0 0;
}
.line3 {
  font-weight: normal;
  margin: 40px 0 0 0;
}

.actions {
  position: sticky;
  top: calc(100% - 115px);
}

.btn {
  margin: 70px 0px 0px;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}
