.layout {
  background: #FFF;
}

.content {
  margin: 0 60px;
  display: flex;
  flex-direction: column;
}

.container {
  background: #FFF;
}

.header {
  padding-left: 20px;
}

.info {
  font-size: 16px;
  line-height: 28px;
  margin: 40px 0;
}

.name {
  font-weight: bold;
  font-size: 18px;
}

.buttonContainer {
  margin-top: 50px;
}
.contact {
  margin: 0 60px;
  font-size: 16px;
}
.contact a {
  color: #000;
}

.btn {
  margin: 25px 40px 20px 40px;
}

.skip {
  margin: 25px 40px 20px 40px;
}
.skip button {
  color: #0089FF;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.line {
  border-top: 1px solid #D8D8D8;
}
