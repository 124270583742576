.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer > textarea {
  appearance: none;
  color: #000;
  height: 70px;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 5px 30px 0px 10px;
  background: none;
  outline: none;
  transition: all 0.3s;
  resize: none;
}
