.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  color: #000;
  height: 60px;
  font-size: 17px;
  line-height: 20px;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0px;
  background: none;
  outline: none;
  transition: all 0.2s;
  font-weight: 500;
}
.input::placeholder {
  color: #787878;
  font-weight: 500;
}
.input.hasIcon {
  padding-left: 60px;
}
.input.noIcon {
  padding: 20px;
}
.focus {
  border-color: #0089ff !important;
}

.cardIcon {
  position: absolute;
  height: 20px;
  top: 20px;
  left: 15px;
}

.icon {
  position: absolute;
  height: 20px;
  top: 20px;
  left: 20px;
}

.iconRight {
  left: auto;
  right: 20px;
}

.error {
  border-bottom: 1px solid rgb(242, 0, 0);
  color: rgb(242, 0, 0);
}

.errorMessage {
  display: block;
  color: rgb(242, 0, 0);
  margin: 5px 0px 0px;
}
