.container {
  background: #F3F3F3;
}

.containerBlack {
  background: linear-gradient(to top, #F3F3F3 50%, #000000 50%);
}

.header, .layout, .head  {
  background: #F3F3F3;
}

.headerBlack {
  background: #000;
}

.layoutBlack {
  background: linear-gradient(to top, #F3F3F3 50%, #000000 50%);
}

.checkboxContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
  text-decoration: none;
  background: #FFF;
  color: #000;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
}

.checkboxContainer.withOptions {
    height: 150px;
}
.checkboxContainer.withFactors {
    height: auto;
}

.checkbox {
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  width: 100%;
  padding: 12px 0;
}
.checkbox.disabled {
  opacity: 0.5;
}


.checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkmark {
  position: absolute;
  right: 10px;
  top: 7px;
  height: 32px;
  width: 32px;
}

.switch {
  width: auto!important;
}

.rowItem {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px;
  text-decoration: none;
  background: #FFF;
  color: #000;
  padding: 15px 25px;
  height: 72px;
  border-radius: 12px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
}
.rowItem.disabled {
  opacity: 0.5;
}
.rowDescription {
  margin: 20px 20px 20px 0;
  font-size: 16px;
  line-height: 20px;
}

.labelContainer {
  font-weight: normal;
  display: flex;
}

.label {
  font-size: 18px;
  height: 23px;
  font-weight: 500;
}

hr.line {
  width: 90%;
  border-top: 1px solid #D8D7D6;
  margin: 30px auto 30px;
}

.content {
  padding: 0 12px;
}

.description {
  margin: 30px 20px;
  font-size: 16px;
  line-height: 20px;
}
.greyDescription {
  color: #A7A7A9;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
}

.totalAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 24px;
  margin-top: 15px;
}
.totalAmount > span:nth-child(2) {
  font-weight: bold;
}

.title {
  color: #FFF;
  display: flex;
  font-size: 30px;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 35px;
}
.title > span {
  margin-top: 15px;
}

.pageDescription {
  font-size: 16px;
  line-height: 20px;
  padding: 30px 5px 30px;
}

.hr {
  border-top: 1px solid #D8D7D6;
  margin: 0px;
}

.error {
  color: rgb(242, 0, 0);
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 5px;
  text-align: center;
  min-height: 18px;
}

.coverageRange {
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.factor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.factorClose {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;
}

.factorTitle {
  font-weight: 500;
}

.infoIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999999
}

.innerContainer {
  margin: 0 10px;
}

.addProperty {
  background: none!important;
  text-decoration: none;
  border: none;
  padding: 0!important;
  color: #0086FF;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.24px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 50px;
  display: block;
}

.pricing {
  margin: 30px 0 20px 0px;
}

.main {
  background: #F3F3F3;
  position: relative;
  min-height: calc(100% - 300px);
}

.mainDetails {
  position: relative;
  min-height: calc(100% - 300px);
  background: #F3F3F3;
}
.mainDetails.twoLines {
  min-height: calc(100% - 286px);
}
