.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer > input {
  appearance: none;
  color: #000;
  height: 60px;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 5px 30px 5px 10px;
  background: none;
  outline: none;
  transition: all 0.3s;
}
.inputContainer.hasIcon > input {
  padding-left: 60px;
}
.inputContainer.hasPrefix > input {
  padding-left: 20px;
}
/*.inputContainer > input:focus {*/
  /*border-color: #000;*/
/*}*/

.icon {
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.prefix {
  position: absolute;
  top: 20px;
}
