.title {
  margin: 53px 20px 30px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
}

.error {
  margin: 0px 20px;
  font-size: 15px;
  color: rgb(242, 0, 0);
}

.modal {
  height: 40vh;
}

.buttons {
  display: flex;
  justify-content: left;
  align-content: center;
  position: sticky;
  width: 100%;
  top: calc(100% - 129px);
}
