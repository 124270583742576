.DayPicker {
  width: 100% !important;
  display: flex;
  justify-content: center;
  border-radius: 15px;

  -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.3s; /* Firefox < 16 */
        -ms-animation: fadein 0.3s; /* Internet Explorer */
         -o-animation: fadein 0.3s; /* Opera < 12.1 */
            animation: fadein 0.3s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.CalendarDay__default {
  border: none;
  color: #0a1f44;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  padding: 0;
}

.CalendarDay__default:hover {
  border: none;
  background: none;
  color: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.CalendarDay__outside {
  opacity: 0.5;
  color: #cacccd;
  visibility: hidden;
}

.CalendarDay__outside:hover {
  background: none;
  color: #cacccd;
}

.CalendarDay__selected.CalendarDay__outside {
  opacity: 1;
}

.DayPicker_weekHeader {
  top: 48px;
}

.DayPicker_weekHeader_li {
  color: #0a1f44;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.DayPicker_weekHeader_li small {
  font-size: inherit;
}

.CalendarDay__selected {
  background-color: #fff !important;
  border: none !important;
  outline: none !important;
}

.CalendarDay__highlighted_calendar > .hbrd_day {
  margin: auto;
  border-radius: 100%;
  background-color: rgba(36, 219, 149, 0.15);
}

.CalendarDay__selected > .hbrd_day {
  margin: auto;
  border-radius: 100%;
  background-color: #000;
  color: #FFF;
}

.CalendarDay__blocked_out_of_range {
  color: #cacccd;
  border: none;
  cursor: not-allowed;
}

.CalendarDay__blocked_out_of_range:hover {
  border: none;
}

.CalendarDay__highlighted_calendar {
  background: #fff !important;
  border: none !important;
  outline: none !important;
}

.CalendarMonth_caption {
  padding-top: 14px;
}
