/* used in router */
.header, .layout  {
  background: #A7A7A9;
}

.headerCompleted {
  color: #fff;
}

.textCompleted {
  color: #fff;
}

.btnBlack {
  margin: 40px 20px 40px;
  width: calc(100% - 40px);
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
}
