.checkmark {
  position: absolute;
  top: 25px;
  right: 15px;
  height: 22px;
  width: 22px;
  border: 1px solid #cecece;
  border-radius: 50%;
}

.checked {
  background-color: #0089ff;
  border: none;
}

.line {
  display: none;
  width: 100%;
  height: 100%;
}

.checked .line {
  display: block;
}
