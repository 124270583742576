.datePicker {
  border-radius: 15px;
  border: 1px solid #FFF;
  margin: 20px 0;
  background: #FFF;
  padding-top: 10px;
}

.pointer {
  cursor: pointer;
}
