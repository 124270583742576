.container {
  margin: auto;
  max-width: var(--max-container-width);

  padding-top: 50px;
  padding-bottom: env(safe-area-inset-bottom);
}

.hideHeader {
  padding-top: 0px;
}

.grey {
  background-color: #f6f6f6;
}

.bodyClass {
  background-color: #ffffff;
}
