.inputContainer {
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer select {
  appearance: none;
  background: transparent;
  font-size: 16px;
}

.inputContainer select:focus {
  box-shadow: 0 0 10px rgba(94, 96, 186, 0.35);
}
