.modalContainer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  height: calc(100% - 30px);
  margin-bottom: -1px; /* fix stripe 1px fixed iframes */
  z-index: 10000;
}
.modal {
  position: relative;
  height: 90%;
  max-height: 586px;
  display: flex;
  flex-direction: column;
  animation: fadein 0.4s;
  background-color: #fff;
  width: calc(100% - 26px);
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.23);
  margin: auto;
  margin-bottom: -1px; /* fix stripe 1px fixed iframes */
  max-width: var(--max-container-width);
}

.scrollArea {
  height: 100%;
  overflow: auto;
  max-width: var(--max-container-width);
}

@supports (-webkit-overflow-scrolling: touch) {
  .modal {
    -webkit-overflow-scrolling: touch; /* https://github.com/scottjehl/Device-Bugs/issues/8 */
  }
}

.modal .icon {
  position: absolute;
  right: 15px;
  top: 15px;
  margin: 0px !important;
  cursor: pointer;
  z-index: 2;
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 9999;
}

.hidden {
  animation: fadeout 0.4s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(50%) scale(1);
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(50%) scale(1);
  }
}
