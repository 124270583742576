/* used in router */
.header, .head {
  background-color: #F3F3F3;
}

.container {
  background: #FFF;
}

.title {
  margin: 0px;
}

.descriptionText {
  margin: 30px 0px;
  font-size: 16px;
  line-height: 20px;
}

.date {
  margin-top: 20px;
}

.pageSelect {
  background-color: #F3F3F3;
}

.service {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 12px;
  text-decoration: none;
  color: #000;
  padding: 12px 20px 12px 18px;
  height: 64px;
  border-radius: 12px;
}
.service .serviceText h4 {
  margin: 0 0 3px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}
.service .serviceRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceDog {
  display: flex;
  justify-content: space-between;
}
.serviceDog .serviceText h4 {
  font-weight: 500;
}

.setting {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  text-decoration: none;
  color: #000;
  padding: 17px 35px 17px 18px;
  height: 74px;
  border-radius: 12px;
  background-color: #F3F3F3;
}
.setting .settingIcon {
  position: absolute;
  left: 30px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.setting .settingText h4 {
  margin: 0 0 3px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}
.setting .settingRight {
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting .arrowIcon {
  position: absolute;
  right: 12px;
  width: 21px;
  height: 21px;
}

.btnEdit {
  margin: 10px 20px;
  background-color: #0089ff;
  color: #ffffff;
  width: calc(100% - 40px);
  height: 48px;
  border-radius: 24px;
  font-size: 15px;
  line-height: 22px;
}

.btnCancel {
  margin: 0px 0px 40px 0px;
  background-color: #fff;
  color: rgb(242, 0, 0);
  width: 100%;
  height: 48px;
  border-radius: 24px;
  border: 1px solid rgb(242, 0, 0);
  font-size: 15px;
  line-height: 22px;
}

hr.line {
  width: 100%;
  border: 1px solid #f3f3f3;
  margin: 0px;
}

.policyText {
  font-size: 12px;
  line-height: 15px;
  margin: 30px 0px;
}
.powered {
  display: flex;
  font-size: 12px;
  line-height: 24px;
  margin: 30px 0px 10px 0;
}

.checkboxContainer .checkbox {
  color: #fff;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  height: 32px;
  margin: 40px 0 0px 0;;
}

.checkboxLabel {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}
.checkboxLabel a {
  color: #000;
  text-decoration: underline;
}

.checkboxContainer .checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkboxContainer .checkbox input {
  cursor: pointer;
  margin-right: 10px;
}

.checkboxContainer .checkmark {
  left: 0px;
  top: 5px;
  border: 2px solid #0089FF;
}

.errorBlock {
  color: rgb(242, 0, 0);
  margin-top: 25px;
  text-align: center;
}
