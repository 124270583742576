.text {
  font-weight: normal;
}

.bold {
  font-weight: 500;
}

.fontSize16 {
  font-size: 16px;
  line-height: 21px;
}
